@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,h2,h3,h4,h5{
    font-family: 'Poppins', sans-serif;
}

a{
    font-family: 'Poppins', sans-serif;
}

p{
    font-family: 'Poppins', sans-serif;
}

 .ExpansionContainer{
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
}

.ExpansionContainer .ExpansionFillButton{
    background-color: #56B149  !important;
    color: #fff !important;
    box-shadow: none !important;
    padding: 15px 30px !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    letter-spacing: 0.56px !important;

}

.ExpansionContainer .ExpansionFillButton .ExpansionFillButtonIcon {
    width: 24px;
    margin-right: 6px;
}

.ExpansionSectionTitle {
    letter-spacing: 0px;
    color: #0B132B;
    opacity: 1;
    font-size: 48px;
    text-align: center;
    font-weight: 600;
    line-height: 56px;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.LighGreyContainer{
    background: #FCFCFC;
    padding: 40px 0;
}

.WhiteContainer{
    background: #fff;
    padding: 40px;
}

/* ======Globle Css End====== */

.ExpansionHeaderContainer{
    background: #fff;
    padding: 15px 0px;
    box-shadow: 0 4px 2px -2px #0000000f;
}

.ExpansionHeaderContainer .ExpansionHeaderInner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.ExpansionHeaderContainer .ExpansionHeaderLogo{
    height: 50px;
}

.ExpansionHeaderContainer .ExpansionHeaderLogo .ExpansionHeaderLogoImg{
    object-fit: contain;
    height: 100%;
    max-width: 270px;   
}



/* ======Header End====== */
.ExpansionBannerContainer{
    background: #FCFCFC;
    padding: 40px 0;
}

.ExpansionBannerContainer .BannerSmallText{
    letter-spacing: 0px;
    color: #56B149;
    text-transform: uppercase;
    opacity: 1;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;

}



.ExpansionBannerContainer .ExpansionBannerGrid{
    margin-top: 40px;
}

.ExpansionBannerContainer .BannerVidoeContainer{

}

.BannerwgtContainer .BannerwgtTopSection{
    background: #56B149;
    padding: 25px;
    text-align: center;
    border-radius: 4px 4px 0 0;
}

.BannerwgtContainer .BannerwgtBodySection{
    background: #fff;
    padding: 20px;
    border: 1px solid #E4E8EC;
    border-radius: 0px 0px 4px 4px;
}

.BannerwgtContainer .BannerwgtBodySection h1{
    color: #444444;
    font-size: 34px;
    line-height: 36px;
    font-weight: 700;
    margin: 0;
}

.BannerwgtBodySection .ExpansionFillButton{
    width: 100%;
    margin-top: 59px;
}

/* ======Banner End====== */

.MuiTabs-indicator{
    background-color: #56B149  !important;
    display: none;
}

.TabsContainerPannel{
    margin-bottom: 40px;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #ffffff !important;
    background: #67b95c;
}
.ExpansionTabBar {
    border-top: #56B149 1px solid;
    border-bottom: #56B149 1px solid;
    background: #56B149;
}

.ExpansionTabBar .MuiTab-root {
    max-height: initial !important;
    color: #fff;
}

.TabsContainerPannel h1 {
    letter-spacing: 0px;
    color: #0B132B;
    opacity: 1;
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    max-width: 630px;
    margin-top: 0;
}

.TabsContainerPannel p {
    letter-spacing: 0px;
    color: #676C7B;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    max-width: 630px;
}

.ExpansionTabsContainer{
    margin-top: 40px;
}
.ExpansionTabBar .MuiTabs-centered {
    justify-content: left;
}

.TabsContainerQoute{
    border-left: #005AFF 2px solid;
    padding-left:20px;
    color: #0B132B;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.propertyImg{
    margin-top: 32px;
    margin-bottom: 24px;
}

.propertyImg img{
    width: 100%;
}

.TextReadMore{
    color: #56B149;
    font-size: 14px;
    letter-spacing: 0.56px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 500;
}

/* ======Tabs End====== */

.ExpansionSummaryContainer h1{
    margin: 0  !important;
    padding: 8px;
    background: #56B149;
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 34px;
    text-align: center;
    border-radius: 4px 4px 0 0;
}

.ExpansionSummaryContainer .ExpansionSummaryBody{
    background: #FCFCFC;
    border:#E4E8EC 1px solid;
    padding: 18px 20px;
    border-radius: 0px 0px 4px 4px;
}

.ExpansionSummaryContainer .ExpansionSummaryBody li {
    display: flex;
    margin-bottom: 20px;
    color: #0B132B;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    align-items: baseline;
}
.ExpansionSummaryContainer .ExpansionSummaryBody li:last-child{
    margin-bottom: 0px;
}

.ExpansionSummaryContainer .ExpansionSummaryBody li svg {
    font-size: 10px;
    color: #A4A7B0;
    margin-right: 12px;
}

/* ====== ExpansionSummary End====== */

.DonaitionCategoriesContainer{
    background: #56B149;
    padding: 24px 20px;
    border-radius: 4px;
    margin-top: 40px;
}

.DonaitionCategoriesContainer h1{
    color: #fff;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 20px;
}

.DonaitionCategoriesContainer .MuiButton-contained {
    display: block;
    margin-bottom: 15px;
}

.DonaitionCategoriesContainer .MuiButton-contained{
    background: #fff  !important;
    border-radius: 4px;
    padding: 20px 15px;
    color: #56B149;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    text-decoration: none;
    box-shadow: none;

}

.DonaitionCategoriesContainer .MuiButton-contained:last-child{
    margin-bottom: 0;
}

.DonaitionCategoriesContainer .MuiButton-contained svg{
    color: #D2D3D8;
    font-size: 15px;
}

/* ====== DonaitionCategoriesContainer End====== */

.ExpansionOurGuestsContainer{
    background: #fff;
    padding: 40px 0;
}

.ExpansionOurGuestsContainer .guestsCard {
    text-align: center;
    position: relative;
}


.ExpansionOurGuestsContainer .guestsCard  .GuestPhoto{
    height: 175px;
    width: 175px;
    display: inline-block;
    background: #E4E8EC;
    border-radius: 100px;
    position: relative;
}
.ExpansionOurGuestsContainer .guestsCard img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 100px;
}

.ExpansionCardContainer{
    margin-top: 30px;
}

.ExpansionOurGuestsContainer .guestsCard h2{
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    color: #0B132B;
    margin-top: 16px;
    line-height: 24px;
}

.ExpansionOurGuestsContainer .guestsCard p{
    margin: 0;
    font-weight: 400;
    color: #676C7B;
    margin-top: 6px;
    line-height: 22px;
}

.ExpansionOurGuestsContainer .guestsCard .guestsCardPlayButton {
    background: #56B149;
    width: 56px;
    height: 56px;
    border-radius: 100px;
    border: #fff 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 110px;
    right: 0px;
    padding: 0 !important;
    color: #fff;
    min-width: initial !important;
}

/* Hajj GUIDES CSS START */	

.DonaitionCategoriesHorizontalContainer{
    background: #56B149;
    padding: 40px 0;
}

.DonaitionCategoriesHorizontalTitle{
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 600;
    line-height: 46px;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 16px;
}

.DonaitionCategoriesHorizontalSubTitle{
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.8;
    font-size: 14px;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.HajjGuidContainer{
	
}

.HajjGuidContainer .HajjGuidContainerLinks {
    background: #FFFFFF 0% 0% no-repeat padding-box  !important;
    border: 1px solid #56B149 !important;
    border-radius: 4px;
    opacity: 1;
    color: #56B149;
    width: 100%;
    padding: 15px 16px;
    font-size: 22px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

.HajjGuidContainer .HajjGuidContainerLinks .MuiSvgIcon-root {
    position: absolute;
    right: 15px;
    font-size: 18px;
}

.DonaitionCategoriesHorizontalContainer .PowerByText {
    color: #FFFFFF99;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
}

.DonaitionCategoriesHorizontalContainer .PowerByText a{
    color: #FFFFFF99;
    font-size: 14px;
    margin-left: 4px;
}

@media(max-width:1150px){
	.HajjGuidContainer .MuiGrid-grid-xs-4 {
		max-width: 50%;
		flex-basis: 50%;
	}
}

@media(max-width:768px){
	.HajjGuidContainer .MuiGrid-grid-xs-4 {
		max-width:100%;
		flex-basis: 100%;
	}
}
/* Hajj GUIDES CSS END */

.ExpansionOurCommunitySupportContainer{
    background: #fff;
    padding: 40px 0;
}

.ExpansionOurCommunitySupportContainer .guestsCard {
    text-align: center;
    position: relative;
}

.ExpansionOurCommunitySupportContainer .GuestPhotoContainer{
    position: relative;
    height: 130px;
    width: 130px;
    margin: 0 auto;
}

.ExpansionOurCommunitySupportContainer .guestsCard .GuestPhoto {
    height: 130px;
    width: 130px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    overflow: hidden;
}

.ExpansionOurCommunitySupportContainer .guestsCard img {
    object-fit: fill;
    width: 130px;
    border-radius: 4px;
}

.ExpansionCardContainer{
    margin-top: 30px;
}

.ExpansionOurCommunitySupportContainer .guestsCard h2{
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    color: #0B132B;
    margin-top: 16px;
    line-height: 20px;
}

.ExpansionOurCommunitySupportContainer .guestsCard p{
    margin: 0;
    font-weight: 400;
    color: #676C7B;
    margin-top: 6px;
    line-height: 22px;
}

.ExpansionOurCommunitySupportContainer .guestsCard .guestsCardPlayButton {
    background: #56B149;
    width: 56px;
    height: 56px;
    border-radius: 100px;
    border: #fff 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    padding: 0 !important;
    color: #fff;
    min-width: initial !important;
    bottom: -10px;
}

.ButtonCenter {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
    margin-bottom: 20px;
}

/* ExpansionOurCommunitySupportContainer CSS END */

.ExpansionFooterContainer{
    background:#FCFCFC;
    padding-top: 40px;
}

.ExpansionFooterContainer .FooterTop{}

.ExpansionFooterContainer .FooterTop h1{
    font-size: 20px;
    color: #444444;
    font-weight: 600;
    margin-top: 0;
}

.ExpansionFooterContainer .FooterTop p{
    font-size: 14px;
    color: #444444;
    font-weight: 400;
    letter-spacing: 0;
    margin:0;
    display: flex;
    align-items: center;
}

.ExpansionFooterContainer .FooterTop p svg {
    font-size: 17px;
    margin-right: 8px;
    color: #7F91A2;
}

.ExpansionFooterContainer .FooterTosSection {
    border-top: #e5e8ec 1px solid;
    margin-top: 30px;
    padding: 14px 0;
}

.ExpansionFooterContainer .FooterTosSection .FooterTosSectionInner{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.ExpansionFooterContainer .FooterTosSection a{
    color:#7F91A2 ;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
}

.ExpansionFooterContainer .FooterTosSection span{
    color:#7F91A2 ;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 15px;
}

.ExpansionFooterContainer .FooterTosSection .CraftedText{
    color:#7F91A2 ;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
}

.FooterBottom{
    background:#F7F8FA ;
    padding: 28px 0;
}

.FooterBottomSectionInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.FooterBottomSectionInner p{
    color:#7F91A2;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    margin: 0;
    display: flex;
}

.FooterBottomSectionInner a{
    color:#7F91A2;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    text-decoration: none;
}

.FooterBottomSectionInner a img{
    width: 145px;
    margin-left: 10px;
}

.FooterBottomSectionInner .VisitText{
    color:#7F91A2;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    text-decoration: none;
}

/* FOOTER END */


.ProjectPhaseContainer{
    display: flex;
}

.ProjectPhaseBox{
    width:25%;
}

.ProjectPhaseBox .ProjectPhaseProgressBox {
    background: #E4E8EC;
    height: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;  
}



.ProjectPhaseBox .ProjectPhaseProgressBox .ProjectPhaseProgressCircul {
    width: 18px;
    height: 18px;
    background: #fff;
    border: #E4E8EC 2px solid;
    border-radius: 100px;
    display: inline-block;
}

.PhaseActive .ProjectPhaseProgressBox {
    background: #56B149 !important;
}

.PhaseActive .ProjectPhaseProgressBox .ProjectPhaseProgressCircul {
    background: #56B149;
    border: #E4E8EC 2px solid;
}

.ProjectPhaseCard {
    background: #fff;
    margin: 8px;
    min-height: 123px;
    position: relative;
    box-shadow: 0px 6px 12px #00000017;
    border-radius: 4px;
    margin-top: 27px;
    text-align: center;
    padding: 32px;
}

.ProjectPhaseCard::after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    left: 46%;  
}

.ProjectPhaseCard .ProjectPhaseStatus {
    width: 50px;
    height: 50px;
    background: #D2D3D8;
    border-radius: 100px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.ProjectPhaseCard .ProjectPhaseStatus svg {
    color: #fff;
    display: none;
}


.ProjectPhaseCard .ProjectPhaseStatus .DoneIcon{
    font-size: 30px;
}

.ProjectPhaseCard .ProjectPhaseCardTitle{
    font-size: 24px;
    color: #D2D3D8;
    line-height: 28px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-top: 16px;
}

.ProjectPhaseBox.PhaseActive.Done .ProjectPhaseStatus{
    background: #676C7B;
}
.Done .ProjectPhaseStatus .DoneIcon{
    display: block;
}
.Inprogress .ProjectPhaseStatus .InProgressIcon{
    display: block;
}
 .Default .ProjectPhaseStatus .defaultIcon{
    display: block;
}


.ProjectPhaseBox.PhaseActive.Done .ProjectPhaseCardTitle{
    color: #676C7B;
}

.ProjectPhaseBox.PhaseActive.Inprogress .ProjectPhaseStatus{
    background: #56B149;
}

.ProjectPhaseBox.PhaseActive.Inprogress .ProjectPhaseCardTitle{
    color: #56B149;
}

.ExpansionConstructionUpdatesContainer .MuiTimelineItem-missingOppositeContent:before {
    flex: none;
    content: "";
    padding: 6px 16px;
}


.ExpansionConstructionUpdatesContainer .MuiTimelineOppositeContent-root {
    flex: none;
    padding: 6px 16px;
    text-align: right;
    margin-right: auto;
    position: relative;
    z-index: 999;
}

.ExpansionConstructionUpdatesContainer .MuiTimelineDot-defaultGrey {
    color: #fafafa;
    border-color: transparent;
    background-color: #005AFF;
    border: #fff 2px solid;
    width: 16px;
    height: 16px;
    padding: 0 !important;
    margin: 0;
    position: relative;
    z-index: 99;
}

.ExpansionConstructionUpdatesContainer .MuiTimelineConnector-root {
    width: 1px;
    flex-grow: 1;
    background-color: #D2D3D8;
}

.ExpansionConstructionUpdatesContainer .MuiTimeline-root {
    display: flex;
    padding: 0;
    flex-grow: 1;
    flex-direction: column;
    margin: 0;
}

.ExpansionConstructionUpdatesContainer .MuiPaper-root {
    background-color: transparent;
}

.ExpansionConstructionUpdatesContainer .MuiAccordion-root .MuiAccordionSummary-root{
    background-color: transparent;
    background: none;
}

.ExpansionConstructionUpdatesContainer .accourdianTitle{
    line-height: 1.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 900px;
    color: #0B132B;
    font-size: 20px;
}

.ExpansionConstructionUpdatesContainer .Mui-expanded .accourdianTitle{
    line-height: 1.5em;
    overflow: hidden;
    white-space: initial;
    text-overflow: initial;
    max-width: 900px;
}

.ExpansionConstructionUpdatesContainer .autherName{
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
    margin-bottom: 16px;
}

.ExpansionConstructionUpdatesContainer .MuiAccordionDetails-root {
    display: block;
}
.ConstructionUpdatesGallery{
    display: flex;
}

.ConstructionUpdatesGallery .ConstructionUpdatesPhotoBox{
    background: #D2D3D8;
    border-radius: 4px;
}

.ConstructionUpdatesGallery .ConstructionUpdatesPhotoBox .ConstructionUpdatesPhoto {
    object-fit: cover;
    width: 100%;
    border-radius: 4px;
}

.ExpansionConstructionUpdatesContainer .TimeLineDate {
    font-weight: 600;
    color: #0B132B;
    font-size: 14px;
    position: relative;
    top: 17px;
    text-transform: uppercase;
}
.ExpansionConstructionUpdatesContainer .MuiAccordionSummary-content.Mui-expanded {
    margin: 13px 0;
}

.ExpansionConstructionUpdatesContainer .MuiTimelineSeparator-root {
    position: relative;
    z-index: 999;
    top: 22px;
}

.ExpansionConstructionUpdatesContainer .MuiTimelineItem-root {
    min-height: initial;
    border-bottom: #E4E8EC 1px solid;
}

.ExpansionConstructionUpdatesContainer .MuiTimeline-root:last-child .MuiTimelineConnector-root{
    position: relative;
    top: -21px;
    
}

/* Construction Updates END */




@media(max-width:1300px ){
    .ExpansionConstructionUpdatesContainer .accourdianTitle {
        width: 70vw;
    }
}

@media(max-width:1000px ){
    .ExpansionConstructionUpdatesContainer .accourdianTitle {
        width: 60vw;
    }
}

@media(max-width:990px ){
    .ExpansionTestimonialsContainer {
        padding: 40px 20px;
    }

    .ExpansionOurGuestsContainer .guestsCard {
        background: #FCFCFC;
        padding: 20px;
        border-radius: 4px;
    }

    .ExpansionOurCommunitySupportContainer .guestsCard {
        background: #FCFCFC;
        padding: 20px;
        border-radius: 4px;
    }

    .ProjectPhaseContainer {
        display: flex;
        overflow-x: scroll;
        padding-top: 11px;
    }
    .ProjectPhaseBox {
        width: 100%;
    }

    .ProjectPhaseCard {
        width: 160px;
    }
    
}



@media(max-width:768px ){
    
    .FooterBottomSectionInner {
        display: block;
        text-align: center;
    }

    .FooterBottomSectionInner p {
        display: block;
    }

    .FooterBottomSectionInner .footerLogo {
        display: flex !important;
        width: 100%;
        justify-content: center;
    }

    .ExpansionHeaderContainer .ExpansionHeaderLogo .ExpansionHeaderLogoImg {
        height: auto;
        max-width: 270px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .MuiTabs-fixed {
        width: 100%;
        overflow-x: auto !important;
    }

    .TabsContainerPannel {
        margin-bottom: 0;
    }
   
}

@media(max-width:600px ){
    .ExpansionHeaderContainer .ExpansionHeaderInner {
        display: block;
        text-align: center;
    }

    .ExpansionContainer .ExpansionFillButton {
        width: 100%;
    }

    .ExpansionSectionTitle {
        font-size: 30px;
        line-height: 35px;
    }

    .ExpansionFooterContainer .FooterTop {
        text-align: center;
    }

    .ExpansionFooterContainer .FooterTop p {
        display: block;
        text-align: center;
    }

    .ExpansionFooterContainer .FooterTosSection .FooterTosSectionInner {
        display: contents;
        text-align: center;
    }

    .ExpansionFooterContainer .FooterTosSection .CraftedText {
        margin-top: 15px;
    }

    .MobileScroll{
        overflow-x: auto;
    }


}

@media(max-width:500px ){
    .MuiTimelineContent-root {
        flex: 1;
        padding: 6px 0;
    }
 
    .MuiAccordionSummary-root {
        padding: 0;
    }

    .ExpansionConstructionUpdatesContainer .MuiTimelineItem-root {
        width: 118%;
    }

    .TestimonialTitle {
        font-size: 30px;
        line-height: 36px;
    }

    .ButtonCenter {
        margin-top: 16px;
        width: 100%;
        margin-bottom: 0;
        margin-right: 15px;
        margin-left: 15px;
    }

}
