@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.ExpansionTestimonialsContainer{
  background:#FCFCFC;
  padding: 40px;
}


.TestimonialArrowButton {
  position: relative;
  top: 213px;
}

.TestimonialArrowButton .TsArrow{
  background: #fff;
  width: 40px;
  height: 40px;
  border: #A4A7B0 1px solid;
}

.TestimonialArrowButton .leftArrow{
  position: absolute;
  left: -100px;
}

.TestimonialArrowButton .RightArrow{
  position: absolute;
  right: -100px;
}

.TestimonialTitle{
  font-size: 48px;
  color: #0B132B;
  font-weight: 600;
  line-height: 56px;
  font-family: 'Poppins', sans-serif;
}

.TestimonialDescription{
  font-size: 24px;
  color: #0B132B;
  font-weight: 400;
  line-height: 38px;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
}

.TestimonialAurtherText{
  font-size: 16px;
  color: #676C7B;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
}

.AurtherThumbnail{
  width: 50px !important;
  height: 50px !important;
}

.TestimonialThumbnailContainer{
  width: 100%;
  margin-top: 46px;
}

.TestimonialVideoImg{
 height: 468px;
}

@media(max-width:1400px ){
  .TestimonialArrowButton {
    display: none;
}
}


@media(max-width:768px ){
  .videoPhotoMoble{
    padding: 10px !important;
  }
}

@media(max-width:500px ){
  .TestimonialVideoImg {
    height: 200px;
}
}

